import { Injectable } from "@angular/core";
import { ATTRIBUTE_LIST_ENDPOINT, CHECK_ATTRIBUTE_NAME_ENDPOINT } from "app/core/constants/api-endpoint.const";
import { ApiService } from "app/core/services/api.service";
import { Observable } from "rxjs";
import { TDSHelperString, TDSSafeAny } from "tds-ui/shared/utility";
import { CheckExistsAttribute, CreateAttribute, IAttributeList, ResponseCreateAttribute, UpdateAttribute } from "../models/attribute.model";

@Injectable({
  providedIn: 'root'
})
export class AttributeService {
  constructor(
    private readonly apiService: ApiService
  ) {
  }

  /**
   * Get list Attributes
   * @param skip 
   * @param take 
   * @param filter 
   * @param storeId 
   * @returns 
   */
  getAttributes$(
    skip: number = 0,
    take: number = 20,
    filter: string = '',
    storeId: string | null = '',
    sort: string = ''
  ): Observable<IAttributeList> {
    const filterParams = TDSHelperString.hasValueString(filter) ? `&filter=${filter}` : '';
    const storeIdParams = TDSHelperString.hasValueString(storeId) ? `&storeId=${storeId}` : '';
    const sortParams = TDSHelperString.hasValueString(sort)? `&sort=${sort}` : '';
    return this.apiService.get<IAttributeList>(`${ATTRIBUTE_LIST_ENDPOINT}?skip=${skip}&take=${take}${filterParams}${storeIdParams}${sortParams}`);
  }

  /**
   * Add new Attributes
   * @param input 
   * @returns 
   */
  addAttributes$(input: CreateAttribute): Observable<ResponseCreateAttribute> {
    return this.apiService.post<ResponseCreateAttribute>(`${ATTRIBUTE_LIST_ENDPOINT}`, input);
  }

  /**
  * Update attribute
  * @param id 
  * @param input 
  * @returns 
  */
  updateAttribute$(id: string, input: UpdateAttribute) {
    return this.apiService.put<TDSSafeAny>(`${ATTRIBUTE_LIST_ENDPOINT}/${id}`, input);
  }

  /**
   * Remove Attributes
   * @param id 
   * @returns 
   */
  removeAttribute$(id: string) {
    return this.apiService.delete<TDSSafeAny>(`${ATTRIBUTE_LIST_ENDPOINT}/${id}`);
  }
}