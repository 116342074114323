import { Injectable } from "@angular/core";
import { ApiService } from "app/core/services/api.service";
import { Observable } from "rxjs";
import { CheckExistsAttributeValue, CreateAttributeValue, IAttributeValueList, ResponseCreateAttributeValue, UpdateAttributeValue } from "../models/attribute-value.model";
import { TDSHelperString, TDSSafeAny } from "tds-ui/shared/utility";
import { ATTRIBUTE_VALUE_LIST_ENDPOINT, CHECK_ATTRIBUTE_VALUE_NAME_ENDPOINT } from "app/core/constants/api-endpoint.const";

@Injectable({
    providedIn: 'root'
})
export class AttributeValueService {
    constructor(
        private readonly apiService: ApiService
    ) {
    }

    
  /**
   * Get list Attributes
   * @param skip 
   * @param take 
   * @param filter 
   * @param storeId 
   * @returns 
   */
  getAttributesValue$(
    skip: number = 0,
    take: number = 20,
    filter: string = '',
    attributeId: string ='',
    storeId: string | null = '',
  ): Observable<IAttributeValueList> {
    const filterParams = TDSHelperString.hasValueString(filter) ? `&filter=${filter}` : '';
    const attributeIdParams = TDSHelperString.hasValueString(attributeId) ? `&attributeId=${attributeId}` : '';
    return this.apiService.get<IAttributeValueList>(`${ATTRIBUTE_VALUE_LIST_ENDPOINT}/${storeId}?skip=${skip}&take=${take}${filterParams}${attributeIdParams}`);
  }
    /**
   * Add new Attribute value
   * @param input 
   * @returns 
   */
  addAttributeValue$(input: CreateAttributeValue): Observable<ResponseCreateAttributeValue> {
    return this.apiService.post<ResponseCreateAttributeValue>(`${ATTRIBUTE_VALUE_LIST_ENDPOINT}`, input);
  }

  /**
  * Update attribute value
  * @param id 
  * @param input 
  * @returns 
  */ 
  updateAttributeValue$(id: string, input: UpdateAttributeValue) {
    return this.apiService.put<TDSSafeAny>(`${ATTRIBUTE_VALUE_LIST_ENDPOINT}/${id}`, input);
  }

  /**
   * Remove Attribute value
   * @param id 
   * @returns 
   */
  removeAttributeValue$(id: string) {
    return this.apiService.delete<TDSSafeAny>(`${ATTRIBUTE_VALUE_LIST_ENDPOINT}/${id}`);
  }
}