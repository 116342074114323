<tds-spin [spinning]="isLoading" class="w-full h-full">
    <form [formGroup]="form" (ngSubmit)="onSave()">
        <tds-form-field>
            <tds-label tdsRequired="true">Danh mục</tds-label>
            <input tdsInput 
                autocomplete="off" 
                placeholder="Tối đa 100 ký tự" 
                formControlName="name"
                [maxlength]="rule.name.maxLength"
                (blur)="trimName()"
                [required]='true' />
                
            @if (this.form.get('name')?.errors?.['required'] || this.form.get('name')?.errors?.['whitespace']) {
                <tds-error>Vui lòng nhập tên danh mục</tds-error>
            }
            @if (this.form.get('name')?.errors?.['maxlength']) {
                <tds-error>Tối đa 100 ký tự</tds-error>
            }
        </tds-form-field>

        @if (isDuplicate) {
            <tds-error>Danh mục đã tồn tại</tds-error>
        }

        <!-- <tds-form-field class="mt-3">
            <tds-label tdsRequired="true">Doanh nghiệp</tds-label>
            <tds-select 
                valueField="id" 
                textField="name" 
                placeholder='Chọn doanh nghiệp' 
                [allowClear]="true"
                [data]="listOfStore"
                [loading]="isLoadingStore"
                formControlName="storeId"
                [allowClear]="true">
            </tds-select>
            @if (this.form.get('storeId')?.errors?.['required']) {
                <tds-error>Vui lòng chọn doanh nghiệp</tds-error>
            }
        </tds-form-field> -->
        <div class="w-full flex justify-end p-4" *tdsModalFooter>
                <button tds-button-outline
                    class="mr-2 min-w-[100px]" 
                    color="secondary"
                    type="button"
                    [disabled]="isLoading"
                    [size]="'md'"
                    (click)="onCancel()">
                    Đóng
                </button>
                <button tds-button 
                    type="submit" 
                    class="min-w-[100px]" 
                    color="primary"
                    [disabled]="isLoading"
                    (click)="onSave()"
                    [size]="'md'">
                    Thêm danh mục
                </button>
        </div>

    </form>
</tds-spin>
